/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "@ionic/angular/css/normalize.css";
@import "@ionic/angular/css/structure.css";
@import "@ionic/angular/css/typography.css";
@import '@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "@ionic/angular/css/padding.css";
@import "@ionic/angular/css/float-elements.css";
@import "@ionic/angular/css/text-alignment.css";
@import "@ionic/angular/css/text-transformation.css";
@import "@ionic/angular/css/flex-utils.css";

@import "swiper/scss";
@import "@ionic/angular/css/ionic-swiper";
@import "swiper/scss/autoplay";
@import "swiper/scss/keyboard";
@import "swiper/scss/pagination";
@import "swiper/scss/scrollbar";
@import "swiper/scss/zoom";

/*
html {
  --ion-dynamic-font: var(--ion-default-dynamic-font);
}
*/
[hidden] {
    display: none !important;
  }

  
.blink{
  animation: blink 2.0s linear infinite;
}
#home_buttons ion-button ion-label ion-badge{
  animation: blink  2.0s linear infinite;
  position: absolute;
    rotate: -30deg;
    font-size: 2em;
}

@keyframes blink{
  0%{
    opacity: 0;
  }
  50%{
    opacity: 1;
  }
  100%{
    opacity: 0;
  }
}

#home_buttons ion-button ion-label ion-badge.win{
  animation: blink 2.0s linear;
  animation-iteration-count: 3.25;
  position: absolute;
    rotate: -30deg;
    font-size: 2em;
}
@keyframes blink{
  0%{
    opacity: 0;
  }
  50%{
    opacity: 1;
  }
  100%{
    opacity: 0;
  }
}


